<template>
  <div class="container mt-4">
    <img
      src="../../assets/images/cookie-policy-img.webp"
      class="d-block m-auto thumbnail"
      alt="..."
    />
    <div class="heading-03 text-primary-05 mt-4 text-center mb-5">
      Politica de confidențialitate
    </div>

    <div class="heading-07 text-neutral-07 mb-2">I. Informații generale</div>

    <p>
      Confidențialitatea datelor dumneavoastră cu caracter personal reprezintă una dintre
      preocupările principale ale GRAPIC ARTISAN BAKERY SRL, cu sediul în Str. Celulozei, Nr.1,
      Bl.25, Sc.E, Et.3, Ap.7, în calitate de operator de date.<br />Acest document are rolul de a
      vă informa cu privire la prelucrarea datelor dumneavoastră cu caracter personal, în contextul
      utilizării paginii de internet https://delicitate.ro.<br />II. Categoriile de date cu caracter
      personal prelucrate
    </p>

    <p>
      II.1. Dacă sunteți client al Site-ului, GRAPIC ARTISAN BAKERY SRL va prelucra datele
      dumneavoastră cu caracter personal, cum ar fi nume şi prenume, telefon, adresa de e-mail,
      adresa de facturare, adresa de livrare, date referitoare la modul în care utilizați Site-ul,
      de exemplu comportamentul/preferinţele/obişnuințele dumneavoastră în cadrul GRAPIC ARTISAN
      BAKERY SRL, precum și orice alte categorii de date pe care le furnizați în mod direct în
      contextul creării contului de utilizator, în contextul plasării unei comenzi prin intermediul
      site-ului sau în orice alt mod care rezultă din utilizarea Site-ului.<br />Dacă pentru a vă
      crea cont de utilizator pe Site, utilizați contul dumneavoastră de Facebook sau Google, GRAPIC
      ARTISAN BAKERY SRL va prelucra următoarele date publice de profil afişate de aplicaţiile
      respective: nume utilizator, adresa de e-mail.<br />În cazul în care alegeți să vă creați cont
      de utilizator doar înainte de a finaliza comanda unui produs disponibil pe Site, se va
      solicita adresa dumneavoastră de e-mail în baza căreia va fi creat automat un cont. În cazul
      în care nu finalizați comanda, adresa de e-mail și celelalte date furnizate nu vor fi stocate
      de GRAPIC ARTISAN BAKERY SRL, iar contul creat va fi șters automat.<br />II.2. Dacă sunteți
      vizitator al Site-ului, GRAPIC ARTISAN BAKERY SRL va prelucra datele dumneavoastră cu caracter
      personal pe care le furnizați în mod direct în contextul utilizării Site-ului, cum ar fi
      datele pe care le furnizați în cadrul secțiunii de contact / întrebări / reclamații, în măsura
      în care ne contactați în acest fel.<br />III. Scopurile și temeiurile de prelucrării
    </p>

    <p>
      III.1. Dacă sunteți client al Site-ului, GRAPIC ARTISAN BAKERY SRL prelucrează datele
      dumneavoastră cu caracter personal astfel:<br />pentru desfășurarea relației contractuale
      dintre dumneavoastră şi GRAPIC ARTISAN BAKERY SRL, respectiv pentru preluarea, validarea,
      expedierea şi facturarea comenzii plasate pe Site, informarea dumneavoastră asupra stării
      comenzii, organizarea returului de produse comandate etc.<br />Temei: Prelucrarea datelor
      dumneavoastră în acest scop are la bază contractul încheiat între dumneavoastră și GRAPIC
      ARTISAN BAKERY SRL, definit în cuprinsul
      <a href="/termeni-si-conditii/">Termenelor și Condițiilor</a>. Furnizarea datelor
      dumneavoastră cu caracter personal este necesară pentru executareaa acestui contract. Refuzul
      furnizării datelor poate avea drept consecință imposibilitatea derulării raporturilor
      contractuale dintre dumneavoastră și GRAPIC ARTISAN BAKERY SRL.<br />pentru îndeplinirea
      obligațiilor legale care incumbă GRAPIC ARTISAN BAKERY SRL în contextul serviciilor prestate
      prin intermediul Site-ului, inclusiv a obligațiilor în materie fiscală, precum și în materie
      de arhivare.<br />Temei: Prelucrarea datelor dumneavoastră pentru acest scop este necesară în
      baza unor obligații legale. Furnizarea datelor dumneavoastră în acest scop este necesară.
      Refuzul furnizării datelor poate avea drept consecință imposibilitatea GRAPIC ARTISAN BAKERY
      SRL de a respecta obligațiile legale care îi revin și deci în imposibilitatea de a vă oferi
      serviciile prin intermediul Site-ului.<br />pentru activităţi de marketing, respectiv pentru
      transmiterea, prin intermediul mijloacelor de comunicare la distanţă (e-mail, sms) de
      comunicări comerciale privind produsele şi serviciile oferite de GRAPIC ARTISAN BAKERY SRL,
      prin intermediul Site-ului.<br />Temei: Prelucrarea datelor dumneavoastră pentru acest scop
      are la bază consimțământul dumneavoastră, dacă alegeți să-l furnizați.<br />Vă puteți exprima
      consimțământul pentru prelucrarea datelor în acest scop prin bifarea căsuței corespunzătoare
      de la momentul creării contului, sau ulterior creării contului, la secțiunea Informațiile
      contului meu. Pentru dezabonarea de la primirea unor astfel de comunicări comerciale puteți
      folosi opţiunea de la finalul fiecărui e-mail/ sms conţinând comunicări comerciale. În plus,
      puteți să vă dezabonați prin accesarea secțiunii Informațiile contului meu.<br />Furnizarea
      datelor dumneavoastră în acest scop este voluntară. Refuzul furnizării consimțământului pentru
      prelucrarea datelor dumneavoastră în acest scop nu va avea urmări negative pentru
      dumneavoastră.<br />în scopul efectuării diverselor analize, raportări privind modul de
      funcționare a Site-ului, realizarea de profiluri de preferinţe de consum, în principal, în
      vederea îmbunătăţiri experienței oferite pe Site.<br />Temei: Prelucrarea datelor
      dumneavoastră pentru acest scop are la bază interesul legitim al GRAPIC ARTISAN BAKERY SRL de
      a îmbunătății permanent experiența clienților pe Site. Furnizarea datelor dumneavoastră în
      acest scop este voluntară. Refuzul furnizării datelor pentru acest scop nu va avea urmări
      negative pentru dumneavoastră.<br />III.2. Dacă sunteți vizitator al Site-ului, GRAPIC ARTISAN
      BAKERY SRL prelucrează datele dumneavoastră cu caracter personal astfel:<br />pentru
      activităţi de marketing, respectiv pentru transmiterea, prin intermediul mijloacelor de
      comunicare la distanţă (e-mail, sms), de comunicări comerciale privind produsele şi serviciile
      oferite de GRAPIC ARTISAN BAKERY SRL, prin intermediul Site-ului.<br />Temei: Prelucrarea
      datelor dumneavoastră pentru acest scop are la bază consimțământul dumneavoastră, dacă alegeți
      să-l furnizați.<br />Vă puteți exprima consimțământul pentru prelucrarea datelor în acest scop
      prin completarea și bifarea căsuței corespunzătoare din formularul pentru abonarea la
      newsletter disponibil pe Site. Pentru dezabonarea de la primirea unor astfel de comunicări
      comerciale puteți folosi opţiunea de la finalul fiecărui e-mail/ sms conţinând comunicări
      comerciale.<br />Furnizarea datelor dumneavoastră în acest scop este voluntară. Refuzul
      furnizării consimțământului pentru prelucrarea datelor dumneavoastră în acest scop nu va avea
      urmări negative pentru dumneavoastră.<br />pentru rezolvarea plângerilor, reclamaţiilor şi
      pentru a monitoriza traficul și a îmbunătăţii experiența dumneavoastră oferită pe Site.<br />Temei:
      Prelucrarea datelor dumneavoastră pentru acest scop are la bază interesul legitim al GRAPIC
      ARTISAN BAKERY SRL de a asigura funcționarea corectă a Site-ului, precum și pentru a
      îmbunătății permanent experiența vizitatorilor Site-ului, inclusiv prin soluționarea
      diferitelor comentarii, întrebări sau reclamații.<br />Furnizarea datelor dumneavoastră în
      acest scop este voluntară. Refuzul furnizării datelor pentru acest scop nu va avea urmări
      negative pentru dumneavoastră.<br />
    </p>
    <div class="heading-07 text-neutral-07 mb-2">IV. Durata pentru care vă prelucrăm datele</div>
    <p>
      Ca principiu, GRAPIC ARTISAN BAKERY SRL va prelucra datele dumneavoastră cu caracter personal
      atât cât este necesar pentru realizarea scopurilor de prelucrare menționate mai sus.<br />În
      cazul în care sunteți client, vom prelucra datele dumneavoastră pe întreaga durată a
      raporturilor contractuale și ulterior conform obligaţiilor legale care revin în sarcina GRAPIC
      ARTISAN BAKERY SRL (de ex., în cazul documentelor justificative financiar-contabile pentru
      care termenul de păstrare prevăzut de lege este de 10 ani de la data încheierii exerciţiului
      financiar în cursul căruia au fost întocmite).<br />În situaţia în care sunteți client și vă
      exercitați opțiunea de ştergere a contului de utilizator, prin apăsarea butonul Ștergere cont
      din secțiunea Informațiile contului meu, GRAPIC ARTISAN BAKERY SRL va interpreta această
      acțiune ca opțiunea dumneavoastră de a vă dezabona de la primirea de comunicări comerciale
      prin care vă ținem la curent despre produsele și serviciile oferite prin intermediul
      site-ului. În acest sens, dacă alegeți să vă ștergeți contul de utilizator, nu vă vom mai
      trimite e-mailuri și/sau sms-uri de acest gen. Totuși, dorim să vă informăm că ștergerea
      contului nu va avea ca efect automat ștergerea datelor dumneavoastră cu caracter personal. În
      cazul în care doriți să nu vă mai fie prelucrate datele cu caracter personal sau dacă doriți
      ștergerea datelor, vă puteți exercita drepturile detaliate la punctul VII de mai jos.. În
      cazul în care solicitați ştergerea contului, însă pe acel cont există cel puţin o comandă
      activă, cererea de ştergere a contului va putea fi înregistrată numai după livrarea produselor
      şi finalizarea ultimei comenzi active.<br />Dacă vă retrageți consimțământul pentru
      prelucrarea datelor în scop de marketing, GRAPIC ARTISAN BAKERY SRL va înceta prelucrarea
      datelor dumneavoastră cu caracter personal în acest scop, fără însă a afecta prelucrările
      desfășurate de GRAPIC ARTISAN BAKERY SRL pe baza consimțământului exprimat de către
      dumneavoastră înainte de retragerea acestuia.<br />V. Dezvăluirea datelor cu caracter personal
    </p>

    <p>
      Pentru îndeplinirea scopurilor de prelucrare, GRAPIC ARTISAN BAKERY SRL poate dezvălui datele
      dumneavoastră către parteneri, către terțe persoane sau entități care sprijină GRAPIC ARTISAN
      BAKERY SRL în desfășurarea activității prin intermediul Site-ului (de exemplu firme de
      curierat, furnizori de servicii IT), ori către autoritățile publice centrale/locale, în
      următoarele cazuri exemplificativ enumerate:<br />pentru administrarea Site-ului;<br />în
      situațiile în care această comunicare ar fi necesară pentru atribuirea de premii sau alte
      facilități persoanelor vizate, obținute ca urmare a participării lor la diverse campanii
      promoționale organizate de către GRAPIC ARTISAN BAKERY SRL prin intermediul Site-ului;<br />pentru
      menținerea, personalizarea și îmbunătățirea Site-ului și a serviciilor derulate prin
      intermediul lui;<br />pentru efectuarea analizei datelor, testarea și cercetarea,
      monitorizarea tendințelor de utilizare și activitate, dezvoltarea caracteristicilor de
      siguranță și autentificarea utilizatorilor;<br />pentru transmiterea de comunicări comerciale
      de marketing, în condițiile și limitele prevăzute de lege;<br />atunci când dezvăluirea
      datelor cu caracter personal este prevăzută de lege etc.<br />
    </p>
    <div class="heading-07 text-neutral-07 mb-2">VI. Transferul datelor cu caracter personal</div>
    <p>
      Datele cu caracter personal furnizate către GRAPIC ARTISAN BAKERY SRL pot fi transferate în
      afara României, dar doar către state din Uniunea Europeană. [Notă: Dacă sunt transferate în
      afara UE, trebuie menționate statele și care sunt garanțiile pentru protecția datelor în
      contextul transferului.]<br />
    </p>
    <div class="heading-07 text-neutral-07 mb-2">VII. Drepturile de care beneficiați</div>
    <p>
      În condițiile prevăzute de legislația în materia prelucrării datelor cu caracter personal, în
      calitate de persoane vizate, beneficiați de următoarele drepturi:<br />dreptul la informare,
      respectiv dreptul de a primi detalii privind activitățile de prelucrare efectuate de către
      GRAPIC ARTISAN BAKERY SRL, conform celor descrise în prezentul document;<br />dreptul de acces
      la date, respectiv dreptul de a obține confirmarea din partea GRAPIC ARTISAN BAKERY SRL cu
      privire la prelucrarea datelor cu caracter personal, precum și detalii privind activitățile de
      prelucrare precum modalitatea în care sunt prelucrate datele, scopul în care se face
      prelucrarea, destinatarii sau categoriile de destinatari ai datelor, etc;<br />dreptul la
      rectificare, respectiv dreptul de a obține corectarea, fără întârzieri justificate, de către
      GRAPIC ARTISAN BAKERY SRL a datelor cu caracter personal inexacte/ nejustificate, precum și
      completarea datelor incomplete; Rectificarea/ completarea va fi comunicată fiecărui destinatar
      la care au fost transmise datele, cu excepția cazului în care acest lucru se dovedește
      imposibil sau presupune eforturi disproporționate.<br />dreptul la ștergerea datelor, fără
      întârzieri nejustificate, („dreptul de a fi uitat”), în cazul in care se aplică unul dintre
      următoarele motive:<br />acestea nu mai sunt necesare pentru îndeplinirea scopurilor pentru
      care au fost colectate sau prelucrate;<br />în cazul în care este retras consimțământul și nu
      există niciun alt temei juridic pentru prelucrare;<br />în cazul în care persoana vizată se
      opune prelucrării și nu există motive legitime care să prevaleze;<br />în cazul în care datele
      cu caracter personal au fost prelucrate ilegal;<br />în cazul în care datele cu caracter
      personal trebuie șterse pentru respectarea unei obligații legale;<br />datele cu caracter
      personal au fost colectate în legătură cu oferirea de servicii ale societății informaționale
      conform dreptului Uniunii sau al dreptului intern sub incidenta căruia se află operatorul.<br />Este
      posibil ca, în urma solicitării de ștergere a datelor, GRAPIC ARTISAN BAKERY SRL să
      anonimizeze aceste date (lipsindu-le astfel de caracterul personal) și să continue în aceste
      condiții prelucrarea pentru scopuri statistice;<br />dreptul la restricționarea prelucrării în
      măsura în care :<br />persoana contestă exactitatea datelor, pe o perioadă care ne permite
      verificarea corectitudinii datelor;<br />prelucrarea este ilegală, iar persoana vizată se
      opune ștergerii datelor cu caracter personal, solicitând în schimb restricționarea utilizării
      lor;<br />operatorul nu mai are nevoie de datele cu caracter personal în scopul prelucrării,
      dar persoana vizată i le solicită pentru constatarea, exercitarea sau apărarea unui drept în
      instanță; sau<br />persoana vizată s-a opus prelucrării (altele decât cele de marketing
      direct), pentru intervalul de timp în care se verifică dacă drepturile legitime ale
      operatorului prevalează asupra celor ale persoanei vizate.<br />dreptul la portabilitatea
      datelor, respectiv (i) dreptul de a primi datele cu caracter personal într-o modalitate
      structurată, folosită în mod obișnuit și într-un format ușor de citit, precum și (ii) dreptul
      ca aceste date să fie transmise de către GRAPIC ARTISAN BAKERY SRL către alt operator de date,
      în măsura în care sunt îndeplinite condițiile prevăzute de lege;<br />dreptul la opoziție – în
      ceea ce privește activitățile de prelucrare se poate exercita prin transmiterea unei
      solicitări conform celor indicate mai jos;<br />în orice moment, din motive legate de situația
      particulară în care se află persoana vizată, ca datele care o vizează să fie prelucrate în
      temeiul interesului legitim al GRAPIC ARTISAN BAKERY SRL sau în temeiul interesului public, cu
      excepția cazurilor în care GRAPIC ARTISAN BAKERY SRL poate demonstra că are motive legitime și
      imperioase care justifică prelucarea și care prevalează asupra intereselor, drepturilor și
      libertăților persoanelor vizate sau că scopul este constatarea, exercitarea sau apararea unui
      drept în instanță;<br />în orice moment, în mod gratuit și fără nicio justificare, că datele
      care o vizează să fie prelucrate în scop de marketing direct.<br />dreptul de a nu fi supus
      unei decizii individuale automate, respectiv dreptul de a nu fi subiectul unei decizii luate
      numai pe baza unor activități de prelucrare automate, inclusiv crearea de profiluri, care
      produce efecte juridice care privesc persoana vizată sau o afectează în mod similar într-o
      măsură semnificativă;<br />dreptul de a vă adresa Autorităţii Naţionale de Supraveghere a
      Prelucrării Datelor cu Caracter Personal sau instanțelor competente, în măsura în care
      considerați necesar.<br />Pentru orice întrebări suplimentare cu privire la modul în care
      datele cu caracter personal sunt prelucrate și pentru a vă exercita drepturile menționate mai
      sus, vă rugăm să vă adresați la adresa de email: salut@delicitate.ro.<br />Această pagină de
      internet folosește fișiere de tip cookie. Pentru mai multe informații cu privire la modul în
      care se folosesc aceste fișiere, vă rugăm să accesați următorul link:
      <router-link :to="{ name: 'CookiePolicy' }"> Politică cookie </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyView',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .thumbnail {
    width: 100%;
  }
}
</style>
